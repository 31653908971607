import { useContext, useEffect } from 'react'
import Text from 'forms/fields/Text'
import MultiCheckbox from 'forms/fields/MultiCheckbox'
import PolicyDrivers from 'forms/fields/PolicyDrivers'
import { AccountContext, FormContext } from 'utils/context'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import { useTranslation } from 'react-i18next'

const OwnerDriver = () => {

  const { t } = useTranslation()
  const { drivers } = useContext(AccountContext)
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)

  const driversToShow = drivers.filter(d => data.policy === d.policy)
  const choices = driversToShow.map(d => [d.driver_id, d.full_name])

  choices.push([0, t('Other')])

  const RegOwnersCallback = value => {
    if (value.includes(0)) {
      clearAllErrors()
      setFieldValue('registered_owner_other', null)
    }
  }

  useEffect(() => {
    if (data.registered_owners) {
      let ownerInPolicy = driversToShow.some(d => data.registered_owners[0] === d.driver_id)
      if (!ownerInPolicy) {
        setFieldValue('registered_owners', [])
      }
    }
  }, []) // eslint-disable-line


  return (
    <fieldset>
      <MultiCheckbox
        name="registered_owners"
        label={t("Who will be the registered owner(s)?")}
        ChangeCallback={RegOwnersCallback}
        choices={choices}
      />
      {data['registered_owners'] && data['registered_owners'].includes(0) && (
        <Text
          name="registered_owner_other"
          label={t("Other")}
        />
      )}
      <PrincipalDrivers helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")}/>
      <strong className="form-title">{`${data.year} ${data.make} ${data.model}`}</strong>
      <PolicyDrivers
        name="principal_driver"
        Stacked
      />
    </fieldset>
  )
}

export default OwnerDriver