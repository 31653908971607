import React from 'react'
import { format, isSameDay, isWeekend, isBefore, isAfter, getDay } from 'date-fns'
import classNames from 'classnames'

const Day = ({ day, date, set, minDate, maxDate }) => {

  let cls = classNames('DateCell', {
    Weekend: isWeekend(day),
    BeforeMin: isBefore(day, minDate),
    AfterMax: isAfter(day, maxDate),
    Selected: isSameDay(date, day),
    Today: isSameDay(day, new Date()),
  })

  let dayStyle = {
    marginLeft: `${format(day, 'd') === '1' && (getDay(day) - 1) * 14.285}%`,
  }

  const handleClick = e => {
    e.preventDefault()
    if (!isBefore(day, minDate) && !isAfter(day, maxDate)) {
      set(day)
    }
  }

  const keyPress = e => {
    e.preventDefault()
    if (e.key === 'Enter') {
      if (!isBefore(day, minDate) && !isAfter(day, maxDate)) {
        set(day)
      }
    }
  }

  return (
    <button onClick={handleClick} onKeyPress={keyPress} className={cls} style={dayStyle} data-testid={format(day, 'd')}>
      <span>{format(day, 'd')}</span>
    </button>
  )
}

export default Day
