import React, { useContext } from 'react'
import { FormContext } from 'utils/context'
import Radio from 'forms/fields/Radio'

const PrincipalDriver = ({prefix, defaultValue, driversChoices}) => {

  const { clearAllErrors } = useContext(FormContext)
  
  const fieldName = name => prefix ? `${prefix}_${name}` : name

  const changeCallBack = () => {
    clearAllErrors()
  }
 
  return (
    <fieldset>
      <Radio
        id={fieldName('driver_id')}
        name={fieldName('driver_id')}
        choices={driversChoices}
        DefaultValue={driversChoices.length === 1 ? driversChoices[0][0] : defaultValue}
        ChangeCallback={changeCallBack}
        Stacked
      />
    </fieldset>
  )
}

export default PrincipalDriver
