import { format, parseISO, isBefore, isAfter } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import LogRocket from 'logrocket'
import { fr } from 'date-fns/locale'
import i18n from 'i18n'
import numeral from 'numeral'

export const isStage = window.location.host.indexOf('stage') !== -1 || window.location.host.indexOf('uat') !== -1
export const isLocal = window.location.host.indexOf('local') !== -1

export const compareDates = (req1, req2) => {
  const req1Date = req1.created ? parseISO(req1.created) : null
  const req2Date = req2.created ? parseISO(req2.created) : null
  if (isBefore(req1Date, req2Date)) {
    return 1
  }
  if (isAfter(req1Date, req2Date)) {
    return -1
  }
  return 0
}

export const policyDate = (policy, key) => {
  if (policy && policy[key]) {
    return translateDate(parseISO(policy[key]), 'MMM dd, yyyy')
  }
  return i18n.t('Invalid Date')
}

export const authFailure = res => {
  if (res && res.detail) {
    const str = res.detail
    if (typeof str === 'string') {
      if (str.toLowerCase().includes('authentication credentials were not provided')) {
        return true
      }
    }
  }
  return false
}

export const addUserToLogRocket = contact => {
  if (contact) {
    LogRocket.identify(contact.email, {
      name: `${contact.first_name} ${contact.last_name}`,
      uuid: contact.contact_uuid,
    })
  }
}

export const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

export const validSessionTime = (time) => {
  if (time && time.time_remaining) {
    if (time.time_remaining === 0) {
      return true
    }
    if (Number.isInteger(time.time_remaining)) {
      return true
    }
  }
  return false
}

export const leadingZero = num => {
  if (num[0] !== 0) {
    return (num < 10 ? '0' : '') + num
  }
}

export const zeroBalance = balance => {
  balance = Number(balance)
  if (balance < 1) {
    return true
  }
  return false
}

export const prettyDate = (date, length) => {
  let dateFormat;

  switch(length) {
    case 'short':
      dateFormat = 'MMM yyyy'
      break;
    case 'long':
      dateFormat = 'EEEE, MMMM do, yyyy'
      break;
    default:
      dateFormat = 'MMM do, yyyy'
  } 

  if (date && typeof date === 'string') {
    let split = date.split('-')
    let d = new Date(split[0], split[1] - 1, split[2])
    return translateDate(d, dateFormat)
  } else if (date && typeof date === 'object') {
    return translateDate(date, dateFormat)
  }
  return date
}

export const confirmDate = (date = null) => {
  if (date && typeof date === 'string') {
    return translateDate(parseISO(date), 'MMMM dd, yyyy')
  }
  return null
}

export const usageFromVehicle = vehicle => {
  if (vehicle && typeof vehicle === 'object') {
    if (vehicle.vehicleUse.toUpperCase() === 'B') {
      return 'business'
    }
    if (vehicle.commuteDistance && vehicle.commuteDistance !== 0) {
      return 'commuting'
    }
    return 'pleasure'
  }
  return null
}

export const titleCase = value => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}

export const isSuspendedOrCanceled = status => {
  if (status) {
    status = status.toLowerCase()
    return status === 'canceled' || status === 'suspended'
  }
  return false
}

export const twoDigitYearToFour = year => {
  year = year.toString()

  if (year.length > 2) {
    return null
  }

  const currentBaseYear = new Date().getFullYear().toString().substr(2, 2)
  const currentLicenceYear = Number(currentBaseYear) - 16

  if (Number(year) > currentLicenceYear) {
    year = `19${year}`;
  } else {
    year = `20${year}`;
  }

  return year
}

export const dateFromString = (value, asDate = false) => {
  const date = value.substr(value.length - 6)
  let year = date.substr(0, 2)
  let yearFull = twoDigitYearToFour(year)
  let month = date.substr(2, 2)
  let day = date.substr(4, 2)
  return asDate ? parseISO(`${yearFull}-${month}-${day}`) : { year: yearFull, month, day } 
}

// Returns true if the current time in Windsor is between 8:30 AM and 5:00 PM, Monday to Friday
export const isHccCallCenterHours = () => {
  const easternNow = utcToZonedTime(new Date(), 'America/Toronto')
  const day = easternNow.getDay()
  const hour = easternNow.getHours()
  const minute = easternNow.getMinutes()
  const outsideHours = ( day === 6 || day === 0 || hour < 8 || hour >= 17 || (hour === 8 && minute < 30) )
  return !outsideHours
}

export const getTenantChatType = (tenant) => {
  let chatType = null
  if (i18n.language === 'en'){
    if (tenant && tenant.slug) {
      if (tenant.use_cxone_chat) chatType = 'cxo'
      else if (tenant.use_olark_chat && tenant.olarkId) chatType = 'olark'
      else if (tenant.use_fresh_chat) chatType = 'fresh'
      else {
        if (isHccCallCenterHours() && tenant.olarkId) {
          chatType = 'olark'
        }
        else {
          if (tenant.after_hours_use_cxone_chat) chatType = 'cxo'
          else if (tenant.after_hours_use_fresh_chat) chatType = 'fresh'
          else if (tenant.olarkId) chatType = 'olark'
        }
      }
    }
  }
  return chatType
}

export const idsToValues = (selectedIds, objectArray, idKey, valueKey) => {
  return selectedIds.map((id, index) => {
    let value = ""
    
    if (index > 0) {
      if (selectedIds.length > 1) {
        value += ", "
      }
    }
    value += objectArray.find(vehicle => id.toString() === vehicle[idKey].toString())[valueKey]
    return value
  })
}

export const vehicleName = (vehiclelId, vehicles) => {
  return vehicles.find(vehicle => vehicle.vehicle_id.toString() === vehiclelId.toString()).full_vehicle_name;
}

export const formatCurrency = (number, numberFormat = '$0,0.00') => {
  let hasDecimal = numberFormat.includes('.')
  return i18n.language === 'fr' ? new Intl.NumberFormat('fr-CA',{
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: hasDecimal ? 2 : 0,
    maximumFractionDigits: hasDecimal ? 2 : 0
  }).format(Number(number)) : numeral(number).format(numberFormat)
}

export const formatNumber = (number, numberFormat = null) => {
  return i18n.language === 'fr' ? new Intl.NumberFormat('fr-CA').format(Number(number)) : (numberFormat ? numeral(number).format(numberFormat) : number)
}

export const translateDate = (date, dateFormat) => {
  return i18n.language === 'fr' ? titleCase(format(date, dateFormat, {locale: fr})) : format(date, dateFormat)
}
