import React, { useContext } from 'react'
import { FormContext, AccountContext } from 'utils/context'
import Drivers from 'forms/fields/Drivers'
import i18n from 'i18n'

const PolicyDrivers = ({ value, exclude, IncludeNotListed, ...props }) => {
  
  let { drivers } = useContext(AccountContext)
  let { data } = useContext(FormContext)

  let finalChoices = []

  drivers.forEach(d => {
    if (Number(d.policy) === Number(data.policy)) {
      finalChoices.push([d.driver_id, `${d.full_name}`])
    }
  })

  if (exclude && exclude.id) {
    finalChoices = finalChoices.filter(ch => ch[0] !== exclude.id)
  }

  if (IncludeNotListed) finalChoices.push([0, i18n.t('Other')])

  return (
    <Drivers
      {...props}
      value={value}
      choices={finalChoices}
    />
  )
}

export default PolicyDrivers