import React, { useContext } from 'react'
import CloseButton from 'components/CloseButton'
import useModal from 'utils/useModal'
import { SupportContext } from 'utils/context'
import { AppContext } from 'utils/context'
import { useTranslation, Trans } from 'react-i18next'
import './style.scss'

const FormSupport = () => {
  const { t, i18n } = useTranslation()
  const { getSnippets } = useContext(SupportContext)
  const { tenant } = useContext(AppContext)

  const { cls, closeModal, dialogOpen } = useModal('bottom', 'firstFocus')

  const getTranslatedTitle = snippet => {
    return i18n.language === 'fr' ? snippet.french_title : snippet.title
  }

  const getTranslatedBody = snippet => {
    return i18n.language === 'fr' ? snippet.french_body : snippet.body
  }

  return dialogOpen === 'form-support' ? (
    <div className={cls}>
      <div className="ModalInner container">
        <div className="FormSupport">
          {getSnippets().map(s =>
            <div key={s.id}>
              <strong>{getTranslatedTitle(s)}</strong>
              <div dangerouslySetInnerHTML={{__html: getTranslatedBody(s)}} />
            </div>
          )}
          <strong>{t('Need help?')}</strong>
          <p>
            <Trans
              i18nKey="uncertainAnswerMessage"
              components={{
                // eslint-disable-next-line
                a1: <a className = "Link" href={tenant.contact_us_url} style={{textDecoration: 'underline', fontWeight: 'bold'}}/>
              }}
            />
          </p>
        </div>
      </div>
      <CloseButton onClick={closeModal} />
    </div>
  ) : null
}

export default FormSupport